body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; /* Show vertical scrollbar */
  

  /* CSS HEX */
  --jet: #343330ff;
  --cinnabar: #e94f37ff;
  --lavender-gray: #bfbccbff;
  --pale-spring-bud: #f4f1bbff;
  --mint-cream: #eff9f0ff;
  --verdigris: #2ca6a4ff;
  --selective-yellow: #fcba04ff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}