.readonly-switch-writeable {
    background-color: var(--cinnabar)
}

.readonly-switch-readonly {
    color: black;
}



.recipes-btn {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: var(--jet);
  font-size: 18px;
  display: inline-block;
}
  
.recipes-btn:hover {
  background-color: transparent;
  color: var(--selective-yellow);
}

.recipes-breadcrumb {
  font-size: 24px;
}

.recipes-breadcrumb a:hover {
  color: var(--selective-yellow);
}